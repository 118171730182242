@import "~react-bootstrap-typeahead/css/Typeahead.css";

.minipanel {
  &.card {
    /*background: linear-gradient(
      135deg,
      rgba(0, 0, 0, 0.22),
      rgba(255, 255, 255, 0.25)
    );
    border-radius: 50px;
    background: linear-gradient(
      -45deg,
      rgba(0, 0, 0, 0.22),
      rgba(255, 255, 255, 0.25)
    );*/
    background: none;
    box-shadow: none;
    border-radius: 20px;
  }

  @include media-breakpoint-up(md) {
    min-width: 600px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.hero-search-panel {
  @extend .container;
  color: $sol-white;

  &--content {
    @extend .row;
    color: $sol-white;
  }
  &--leftside {
    @extend .col-md-7;
  }

  &--rightside {
    @extend .col-md-5;
  }
  // customization for typeahead token in search panel
  .rbt-token {
    color: $dark-red;
    background-color: lighten($swiss-red, 47%);
  }
}
