$dark-border-color: darken($sol-light-gray, 10);
$navigation-width: 250px;
@keyframes toolbar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.ngh-console {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100vw;
  overflow: hidden;
  &__toolbar {
    display: flex;
    align-content: center;
    min-height: 45px;
    background-color: $sol-light-gray;
    width: 100vw;
    border-bottom: 1px solid $dark-border-color;

    &__loading {
      position: absolute;
      height: 10px;
      width: 100vw;
      &__indicator {
        height: 100%;
        width: 0%;
        animation-name: toolbar;
        animation-iteration-count: infinite;
        animation-duration: 5s;
        background-color: $sol-green;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      user-select: none;
      cursor: pointer;
      height: 45px;
      min-width: 45px;
      font-size: 14px;
      padding-left: 5px;
      padding-right: 5px;

      @include media-breakpoint-down(sm) {
        &__text {
          display: none;
        }
      }

      &__count {
        position: absolute;
        top: -6px;
        font-size: 12px;
        border-radius: 5px;
        height: 16px;
        text-align: center;
        width: 18px;
        font-weight: 600;
        left: 15px;
        color: white;
        background-color: rgba(255, 0, 0, 0.719);
      }

      &--sidebar {
        //@extend .m-0;
        //@extend .pl-4;
        //padding-left: 1px;
        .ngh-console__toolbar__item__text {
          display: block;
        }
      }

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &--divider {
        max-height: 1px;
        background-color: $dark-border-color;
        padding: 0;
        pointer-events: none;
        cursor: default;
      }

      &--toolbar {
        justify-content: center;
        padding-left: 0;
        padding-right: 2px;
        min-height: 100%;
      }

      &--active {
        background-color: darken($sol-light-gray, 5);
      }

      &:hover {
        background-color: darken($sol-light-gray, 5);
      }

      &__icon {
        color: $facebook-blue;
        margin-right: 10px;
        margin-left: 10px;
        @include media-breakpoint-down(sm) {
          margin-right: 4px;
          margin-left: 2px;
        }
        min-width: 0px;
        &--toolbar {
          min-width: 0px;
        }
        &--menu {
          min-width: 20px;
        }
        &--sidebar {
          min-width: 30px;
        }
      }

      &__menu {
        background-color: lighten($sol-light-gray, 10);
        border-color: $dark-border-color;
        font-size: 14px;

        &__item {
          display: flex;
          height: 40px;
          align-items: center;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex: 1;
    min-height: 0;
    overflow: hidden;

    &__navigation {
      position: relative;
      background-color: $sol-light-gray;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 999;
      display: block;
      height: 100%;
      min-width: $navigation-width;
      max-width: $navigation-width;
      margin-left: -$navigation-width;
      border-right: 1px solid $dark-border-color;
      transform: translateX(-100%);
      transition: all 0.4s ease-in;

      @include media-breakpoint-down(sm) {
        position: fixed;
        min-width: 100%;
      }
      &--open {
        transition: all 0.4s ease-out;
        margin-left: 0;
        transform: translateX(0);
      }
    }

    &__panel {
      flex: 1 1 auto;
      /*padding: 25px;
      padding-top: 5px;
      padding-bottom: 50px;*/
      background-color: darken(white, 1);
      overflow-y: auto;
    }
  }
}
