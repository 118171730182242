@import url('https://fonts.googleapis.com/css?family=Macondo&display=swap');

.topnavbar {
    transition: all 0.4s ease-in-out;
    //border-bottom: 1px solid $sol-gray;
    font-weight: 100;
    font-size: 10px;
    color: red;
    &.scroll {
      //border: 1px solid darken($sol-white, 10%);
      .navbar-brand {
          font-weight: 100;         
          .svg-inline--fa  {
              color: darken($swiss-red, 10%);
      //        color: white;
          }
      }
    }     
    &.topnotification {
        margin-top: 100px;
    }
    @include media-breakpoint-down(sm) { 
        &.open {
            background-color: $dark-red;
        }
    }
    .brand-typo {        
        @extend .ml-2;
        font-family: 'Macondo', cursive;        
        font-size: 15px;        
        font-weight: 100;
    }

    .socialtoken {
        &.dropdown-toggle {
            &::after {
            border:0;
            margin:0;
            padding:0;
            }
        }
        transition: all 0.2s ease-in;
        &:hover {
            transform: scale(1.1);
        }
    }
}