@import "HeroSearchPanel";

.hero-landing-page {
    display: flex;    
    align-items: flex-end;
    justify-content: center;
    background-color: $sol-info;
    //background-image: $red-gradient;
    //box-shadow: 0px 0px 20px 0px #0F0F0F;
    //box-shadow: 0px 0px 25px 0px $dark-red;
    padding-top: 80px;
    @include media-breakpoint-down(sm) { 
        padding: 0;
        padding-top: 60px;
        border-bottom-left-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
     }
}