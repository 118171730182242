
$swiss-red: #ff0000;
$dark-red: #4c0101;
$sol-primary: #dd851b;
$sol-secondary: #0f0a0a;
$sol-brown: #875111;
$sol-white: #f5efed;
$sol-info: #2292a4;
$sol-green: #bdbf09;
$sol-gray: #262625;
$sol-blue: #4b6bb7;
$facebook-blue: #507cc0;
$sol-light-gray: rgb(244,244,244);

$col: rgba($sol-gray, 0.2);

$red-gradient: linear-gradient(180deg, #4c0101 0%, #ff0000 100%);

$body-bg: rgb(244,244,244);
$body-color: $sol-secondary;
$enable-shadows: true;

$font-weight-base: 300;
$font-size-base: 1rem;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=League+Script&display=swap');

$font-family-sans-serif:  'Lato', sans-serif;// 'Roboto', sans-serif;

$theme-colors: (
  "brand": $sol-primary,
  "solblue": $sol-blue,
  "solgray": $sol-light-gray,
  "primary": $sol-blue,
  "swiss": $swiss-red,
  "darkred": $dark-red,
  "solwhite": $sol-white,
  "dark" : $sol-gray,  
  "facebook": $facebook-blue,
  "google": #dd4b39,
  "microsoft": #2672ec
);

$breadcrumb-bg: none;
//$breadcrumb-divider: "\00a0";
$breadcrumb-padding-x: 0;
