.ngh-hero {
    @extend .jumbotron-fluid;
    min-height: 400px;
    background-size: cover;
    &__background-cover {
        min-height: 100%;
        height: 400px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0);
    }

    background-color: $sol-info;
    color: $sol-white;
}