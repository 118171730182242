$swiss-red: #ff0000;
$dark-red: #4c0101;
$sol-primary: #dd851b;
$sol-secondary: #0f0a0a;
$sol-brown: #875111;
$sol-white: #f5efed;
$sol-info: #2292a4;
$sol-green: #bdbf09;
$sol-gray: #262625;
$facebook-blue: #507cc0;
$sol-blue: #4b6bb7;

$col: rgba($sol-gray, 0.2);

$red-gradient: linear-gradient(180deg, #4c0101 0%, #ff0000 100%);

$body-bg: rgb(244,244,244);
$body-color: $sol-secondary;
$enable-shadows: true;

$font-weight-base: 300;
$font-size-base: 1rem;

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;