.search-result-list {
  .card-columns {
    @include media-breakpoint-up(sm) {
      column-count: 2;
    }
    @include media-breakpoint-up(md) {
      column-count: 3;
    }
    @include media-breakpoint-up(lg) {
      column-count: 4;
    }
  }
}

#landingpage-shit {  
  overflow-y: auto;
  height: 100vh;
}

.content-section {
  border-bottom: 1px solid lighten($sol-gray, 70);
  margin-bottom: 20px;
  padding-left:0;
  position: sticky;
    top: 0;
  h1,h2,h3,h4 {    
    margin-left: 0;
    padding-left: 0;
    font-weight: 300;
    
  }
}