html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
//  overflow: hidden;
  -webkit-overflow-scrolling: unset;
}

#root {
  height: 100%;
  width: 100%;
}

@import "~roboto-fontface/css/roboto/roboto-fontface.css";
/* eslint-disable */

.DokaOverlay {
  position: relative;
  overflow: hidden;
}

.DokaOverlay > img {
  display: block;
  width: 100%;
  height: auto;
}

.DokaOverlay > .DokaContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@import "theme";
@import "~bootstrap/scss/bootstrap";

@import "./pages/SplashScreen.scss";

@import "./components/LoginPanel";
@import "./components/TopNavigationBar";
@import "./components/HeroLandingPage";
@import "./components/HeroDetailPage";
@import "./components/Hero";

@import "./components/TopBar/index";
@import "./components/console/console";
@import "./pages/LandingPage";
@import "./components/Editor";

@import "./vendor/draftjs";

.bg-dialog {
  background-color: darken($sol-white, 3);
}
/*
.breadcrumb-item+.breadcrumb-item::before{
    font: normal normal normal 14px/1 FontAwesome;
    background: url('data:image/svg+xml;utf8,<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" class="svg-inline--fa fa-angle-right fa-w-8" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"></path></svg>');
    background-repeat: no-repeat;        
    content: $breadcrumb-divider;
  }
*/

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.App {
  height: 100vh;
  width: 100vw;
  opacity: 0;
  // overlfow: hidden
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.card {
  background: none;
  border: 0;
  box-shadow: 0px 1px 24px 0px $col;
}

.card-product {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale3d(1.1, 1.1, 1.1);
  }
}

.regular-layout {
  display: flex;
  padding-top: 50px;
  height: 100vh;
  width: 100vw;  
  //padding-bottom: 100px;
  .main-center {

  }
}

.card-group {
  &.admin {
    margin-left: -15px;

    .card {
      min-width: 300px;
      margin: 15px;
      box-shadow: 0px 1px 24px 0px $col;
      transition: box-shadow 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 1px 10px 0px $col;
      }
      .card-img-top {
        width: 70%;
        max-height: 150px;
        margin-top: 15px;
        align-self: center;
        justify-self: center;
      }
    }
  }
}

.wizard-steps-buttons {
  display: flex;
  align-items: center;
}

.products-container {
  border: 0px solid yellowgreen;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100vw;

  .products-dashboard {
    flex: 1;
    transition: width 1s ease-out;
    height: 100%;
  }

  .sidebar {
    width: 0;
    height: 100vh;
    transition: all 0.4s ease-in-out;
    background-color: $sol-white;
    box-shadow: 0px 0px 10px 0px black;
    &.open {
      width: 80%;
    }
  }
}

.regular-layout {
  overflow: auto;
}

table.table-hover tbody tr:hover {
  background-color: lighten($sol-blue, 40);
}

.sol-edit {
  transition: transform 0.4s ease-out;
  &:hover {
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

.modal-dialog {
  min-width: 600px;
}

@include media-breakpoint-down(sm) {
  .modal {
    &.fade {
      transform: translateY(100vh);
      opacity: 1;
      transition: all 0.4s ease-in-out;
      display: block !important;
    }
    &.show {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .modal-dialog {
    display: flex;
    align-items: stretch;
    margin: 0;
    height: 100vh;
    min-width: 100vw;
    form {
      display: flex;
      flex-direction: column;
      height: 100%;
      .modal-header {
        border-radius: 0;
        padding: 0;
        button {
          line-height: 50px;
          min-width: 50px;
          
        }
      }
      .modal-title {
        font-weight: 300;
        font-size: 16px;
      }
      .modal-body {
        flex: 1;
        overflow: auto;
      }
      .modal-footer {
        border-radius: 0;
      }
    }
  }
}


.card {
  box-shadow: none;
  border: 1px solid lighten($sol-gray, 75);
  .card-header {
    border-bottom: 1px solid lighten($sol-gray, 75);
  }
}


.image-chooser {
  cursor: pointer;
  &--selected {
    border: 1px double $swiss-red;
  }
}
.placeholder {
  border: 1px solid lighten($sol-gray, 70);
  border-radius: 10px;
  color: lighten($sol-gray, 50);
  opacity: 0.4;
}

.ngh-columns {
  display: grid;
  grid-auto-columns: auto;
  > * {
    flex-grow: 1;
  }
}


.ais-SearchBox-input {
  line-height: normal;
}



.slide-enter,
.slide-exit {
  transition: transform 400ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}