.ngh-ProfileToggle {
    overflow: hidden;
    cursor: pointer;
    &__menu {
        position: absolute;
        top: 0;
        background-color: white;
        margin-top: 30px;
        margin-right: 50px;
        min-width: 200px;
        z-index: 999;
        transition: all 0.4s ease-out;
        &--open {
            display: block;
            transform: translateY(0);
            opacity: 1;
        }

        &--closed {
            transform: translateY(-20px);
            opacity: 0;
        }

        &:focus {            
            border: 2px solid red;
        }
    }

}