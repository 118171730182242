.ngh-editor {
  &__component {
    position: relative;
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
    outline: none;
    cursor: pointer;

    & > * {
      grid-column: 1 / 1;
      grid-row: 1 / 1;
    }

    .column-add {
      position: absolute;
      display: flex;
      align-content: center;
      top: 0px;
      z-index: 20000;
      right: 12px;
      height: 100%;
      width: 25px;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      &:hover {
        opacity: 1;
      }
      &--right {
      }
    }

    &__overlay {
      width: 100%;
      height: 100%;
      z-index: 5;
      background: none;
      pointer-events: none;

      .type {
        display: none;
        font-size: 10px;
        padding: 5px;
      }
      &--dragging {
        opacity: 0;
      }
      &--candrop {
        background-color: rgba(14, 155, 38, 0.452);
      }
      &--candrop-down {
        border-bottom: 2px solid rgb(0, 102, 255);
      }
      &--candrop-up {
        border-top: 2px solid rgb(0, 102, 255);
      }
      &--selected {
        border: 2px solid rgb(255, 0, 212);
      }
      &--over {
        border: 2px dotted rgb(255, 0, 212);
        cursor: pointer;

        .type {
          display: block;
          background-color: rgb(255, 0, 212);
          position: absolute;
          color: white;
          bottom: 0;
          right: 0;
        }
        //background-color: rgba(255, 0, 255, 0.336);
      }
    }
    &__content {
      z-index: 2;
      width: 100%;
      height: 100%;
    }
  }
}

.ngh-columns {
  &__item {
    position: relative;
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
    & > * {
      grid-column: 1 / 1;
      grid-row: 1 / 1;
    }

    &__add {
      display: flex;
      align-items: center;
      z-index: 900;
      height: 50%;
      margin-top: 2%;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
      &--right {
        position: absolute;
        right: 0;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.touch {
  .ngh-editor__component__overlay--over {
    display: none;
  }
}

.direction-reverse {
  flex-direction: column-reverse;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.text-image {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.container,
.container-fluid {
  .text-content.mt5.container-fluid {
    padding: 0;
  }
  .text-content {
    font-size: 16px;
    font-weight: 200;
  }

  .text-header {
    font-size: 16px;
    font-weight: 400;
  }
}
