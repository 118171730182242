.login-panel {
    @extend .m-3;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 300px;
    
    .section {        
        @extend .mb-3;
        width: 100%;        

        .text-muted {
            font-size: 12px;
        }
    }
}