.topnavbar{
    position: fixed;
    top: 0;
    display: flex;
    width: 100vw;
    height: 55px;
    align-items: center;
    z-index: 800;
    //border-bottom: 1px solid $sol-brown;    
    margin-bottom: 20px;

    &.scroll {
        box-shadow: 0px 1px 24px 0px $col;
    }

    .system-logo {        
        @extend .pl-3;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; 
        font-family: 'Macondo', cursive;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
       // border-right: 1px solid $sol-brown;        
        a {
          color: $sol-primary;
          text-decoration: none;
          &:hover {
              color: lighten($sol-brown, 10%);
          }
        }

        .firstds {
            @extend .text-brand;
            &:hover {
            //    color: lighten($sol-primary, 10%);
            }
        }
        .second, .first {
            color: $sol-secondary;
            font-weight: 200;
            &:hover {
             //   color: lighten($sol-secondary, 10%);
            }
        }
    }
}