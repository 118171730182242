@import "../../styles";

@keyframes rotate {
  from {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.ngh-AppBar {
  position: fixed;
  width: 100vw;
  display: flex;
  z-index: 999;
  background-color: $sol-blue;
  color: $sol-white;
  line-height: 50px;
  max-height: 52px;

  &--full {
    padding-top: 100px;
  }

  &--BrandIcon {
  }

  &--Right {
    width: 40px;
    text-align: center;
    display: block;

    @include md {
      display: none;
    }

    &__open {        
      .icon {
        animation-name: rotate;
        animation-duration: 0.4s;
        animation-timing-function: ease-in-out;
      }
    }
  }

  &--title {
    font-weight: 300;
    opacity: 0.9;
    padding-right: 15px;    
    &:hover {
      opacity: 1;
    }
  }

  &--spacer {
    margin-left: auto;
    margin-right: auto;
  }

  &--Nav {
    display: flex;
    position: fixed;
    top: 50px;
    padding-top: 15px;
    height: 100%;
    background-color: $sol-blue;
    left: 0;
    width: 100vw;
    flex-direction: column;
    z-index: 10;
    transform: translateX(100%);
    transition: transform 0.4s ease-in;

    &__open {
      transform: translateX(0);
      transition: transform 0.4s ease-out;
    }

    border-top: 1px solid darken($sol-blue, 10);

    @include md {
      position: relative;
      background: none;
      transform: translateX(0);
      top: 0;
      left: 0;
      flex-direction: row;
      width: auto;
      border-top: 0;
      height: auto;
      padding-top: 0;
      margin-left: 15px;
    }
  }

  &--NavItem {
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
    user-select: none;
    border-bottom: 2px solid transparent;
    color: transparentize($sol-white, 0.3);
    //transition: all .4s ease-in-out;
    .icon {
        color: transparentize($sol-white, 0.3);
      //  transition: all .2s ease-in-out;
      }
    &:hover {        
      background-color: darken($sol-blue, 3);
      color: $sol-white;
      .icon {
          color: $sol-white;
      }
    }

    &__active {
        font-weight: 300;
        color: $sol-white;
        .icon {
            color: $sol-white;
        }
      background-color: darken($sol-blue, 3);
    }
  }
  
}

.ngh-Divider {
  background-color: lighten($sol-blue, 10);
  width: 1px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;

  @include lg {
    display: block;
  }
}

body {
  background-color: rgb(244, 244, 244);
}
